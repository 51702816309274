<template>
  <Modal :onClose="onClose" class="z-11000">
    <div class="root">
      <div  class="flex flex-grow">
        <img class="rounded-tl-lg rounded-bl-lg leftImg" v-show="step===1" src="../assets/images/audioOnboarding/1.png">
        <img class="rounded-tl-lg rounded-bl-lg leftImg" v-show="step===2" src="../assets/images/audioOnboarding/5.png">
        <img class="rounded-tl-lg rounded-bl-lg leftImg" v-show="step===3" src="../assets/images/audioOnboarding/5.png">

        <div class="flex flex-col justify-between w-full p-6">
          <div v-show="step===1">
            <h2 class="text-2xl font-semibold font-h text-primary">
              Welcome to Spext
            </h2>

            <div class="mt-4 text-xs text-primary-800">
             Here you can correct the transcript to improve it's accuracy
            </div>
          </div>

          <div v-show="step===2">
            <h2 class="text-2xl font-semibold font-h text-primary">
              Quick editing and sharing in a click!
            </h2>

            <div class="mt-4 text-xs">
              <img src="../assets/images/transcriptOnboarding/21.png" class="mt-4">
            </div>
          </div>

          <div v-show="step===3">
            <h2 class="text-2xl font-semibold font-h text-primary">
              One last thing
            </h2>

            <div class="mt-4 text-xs text-primary-800">
              Any changes you make here will <b class="text-red">NOT</b> be reflected in the media.
            </div>
          </div>

          <div class="flex items-center justify-end">
            <div v-show="prevStep>=0">
              <Button size="small" variant="secondary" :onClick="goToPrev" classname="mr-2.5">Previous</Button>
            </div>

            <div v-show="nextStep>0 && nextStep<stepsOrder.length">
              <Button size="small" :onClick="goToNext" classname="pr-1.5">
                Next <ChevronRight classname="h-3 w-3 ml-0.75"/>
              </Button>
            </div>

            <div v-show="nextStep===stepsOrder.length">
              <Button size="small" :onClick="close">
                <span>Got it</span>
                <i class="twa twa-thumbsup"/>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Modal>
</template>

<script>
import { mapActions } from 'vuex'
import Modal from '@/components/base/dialogs/Modal.vue'
import Button from '@/components/base/buttons/Button.vue'
import ChevronRight from '@/components/base/icons/ChevronRight.vue'
require('../assets/css/twemoji-awesome.css')
export default {
  components: {
    Modal,
    Button,
    ChevronRight
  },
  data () {
    return {
      step: 1,
      stepsOrder: [1, 2, 3],
      isActive: false
    }
  },
  computed: {
    prevStep () {
      let index = this.stepsOrder.indexOf(this.step)
      if (index < 0) return -1
      return index - 1
    },
    nextStep () {
      let index = this.stepsOrder.indexOf(this.step)
      if (index < 0) return -1
      return index + 1
    }
  },
  methods: {
    ...mapActions({
      closeModal: 'dialogs/closeModal'
    }),
    onClose: function() {
      localStorage['onBoardingEditTranscript'] = 'shown'
      window.analytics.track('popup_onboardingComplete', { type: 'transcript' })
    },
    close: function() {
      this.closeModal()
      this.onClose()
    },
    goToPrev () {
      if (this.prevStep < 0) return
      this.step = this.stepsOrder[this.prevStep]
    },
    goToNext () {
      if (this.nextStep < 0) return
      this.step = this.stepsOrder[this.nextStep]
    }
  }
}

</script>
<style lang="less" scoped>
.root {
  width: 560px;
}

.leftImg  {
  height: 272px;
  width: 210px;
}
</style>
