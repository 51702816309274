import { render, staticRenderFns } from "./TranscriptOnBoarding.vue?vue&type=template&id=b085f6e4&scoped=true&"
import script from "./TranscriptOnBoarding.vue?vue&type=script&lang=js&"
export * from "./TranscriptOnBoarding.vue?vue&type=script&lang=js&"
import style0 from "./TranscriptOnBoarding.vue?vue&type=style&index=0&id=b085f6e4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b085f6e4",
  null
  
)

export default component.exports